import React from "react";
import "./Popup.css"; // Ensure you have linked your popup styles

const Popup = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-box" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="close-btn">
          X
        </button>
        <h4>Disclaimer</h4>
        <p>
          THIS DOCUMENT IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND DOES NOT
          CONSTITUTE ANY FORM OF LEGAL, FINANCIAL, TAX, INVESTMENT, OR OTHER
          PROFESSIONAL ADVICE. NO ENTITY ASSOCIATED WITH THIS TOKEN SHALL BE
          HELD LIABLE FOR ANY DIRECT OR INDIRECT LOSSES OR DAMAGES ARISING FROM
          THE USE OF OR RELIANCE ON THIS DOCUMENT.
        </p>
        <p>
          NOTHING IN THIS DOCUMENT SHOULD BE CONSTRUED AS AN OFFER OR
          SOLICITATION TO SELL OR PURCHASE SECURITIES, INVESTMENT PRODUCTS, OR
          ANY FINANCIAL INSTRUMENTS IN ANY JURISDICTION. THIS TOKEN IS A UTILITY
          TOKEN INTENDED SOLELY FOR USE WITHIN ITS DESIGNATED ECOSYSTEM AND DOES
          NOT CONFER ANY RIGHTS OF OWNERSHIP, VOTING RIGHTS, DIVIDENDS, OR OTHER
          SIMILAR RIGHTS. THIS TOKEN IS NOT, AND SHALL NOT BE, CONSIDERED A
          SECURITY, CURRENCY, OR OTHER FORM OF LEGAL TENDER.
        </p>
        <p>
          Risk Disclaimer: PARTICIPATION IN THIS TOKEN SALE INVOLVES SIGNIFICANT
          RISKS, INCLUDING BUT NOT LIMITED TO THE RISK OF LOSING ALL AMOUNTS
          PAID. HOLDERS OF THIS TOKEN ASSUME FULL RESPONSIBILITY FOR ANY RISKS
          ASSOCIATED WITH ITS ACQUISITION, HOLDING, OR USE, INCLUDING POTENTIAL
          FLUCTUATIONS IN VALUE, LOSS OF ACCESS DUE TO FORGOTTEN CREDENTIALS, OR
          CYBERSECURITY BREACHES. THE ISSUER MAKES NO REPRESENTATIONS OR
          WARRANTIES REGARDING THE FUTURE VALUE, FUNCTIONALITY, OR SECURITY OF
          THE TOKEN. PARTICIPANTS EXPRESSLY UNDERSTAND AND AGREE THAT THE TOKEN
          IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT.
        </p>
        <p>
          No Expectation of Profit or Investment Return: THIS TOKEN IS DESIGNED
          SOLELY FOR USE WITHIN ITS DESIGNATED ECOSYSTEM. IT IS NOT AN
          INVESTMENT AND SHOULD NOT BE ACQUIRED WITH ANY EXPECTATION OF PROFIT,
          DIVIDENDS, OR OTHER FINANCIAL GAIN. ANY PURCHASER WHO ACQUIRES THIS
          TOKEN FOR SPECULATIVE PURPOSES DOES SO AT THEIR OWN RISK AND MAY LOSE
          THE ENTIRETY OF THEIR INVESTMENT. THE ISSUING ENTITY DISCLAIMS ALL
          RESPONSIBILITY FOR ANY PURCHASER'S FINANCIAL LOSSES OR EXPECTATIONS.
        </p>
        <p>
          Regulatory Compliance and Changes: THE LEGAL AND REGULATORY
          ENVIRONMENT FOR CRYPTOCURRENCY AND DIGITAL TOKENS IS IN A CONSTANT
          STATE OF EVOLUTION. PURCHASERS ARE SOLELY RESPONSIBLE FOR ENSURING
          THAT THEIR PURCHASE, HOLDING, OR USE OF THIS TOKEN IS LEGAL IN THEIR
          JURISDICTION. ANY CHANGES IN REGULATIONS, GOVERNMENTAL POLICIES, OR
          LAW ENFORCEMENT MAY NEGATIVELY IMPACT THE USE, TRANSFER, OR VALUE OF
          THE TOKEN. THE ISSUING ENTITY ASSUMES NO LIABILITY FOR ANY REGULATORY
          CHANGES THAT MAY IMPACT THE TOKEN OR ITS HOLDERS.
        </p>
        <p>
          Limitation of Liability: TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
          LAW, THE ISSUING ENTITY, ITS AFFILIATES, AND THEIR RESPECTIVE
          OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS SHALL NOT BE HELD LIABLE
          FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
          DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, GOODWILL, USE,
          OR DATA ARISING OUT OF OR RELATED TO THE USE OR INABILITY TO USE THIS
          TOKEN, EVEN IF SUCH DAMAGES WERE FORESEEABLE.
        </p>
        <p>
          Prohibition on U.S. Residents or Citizens: THE SALE, PURCHASE, OR
          TRANSFER OF THIS TOKEN IS PROHIBITED FOR CITIZENS OR RESIDENTS OF THE
          UNITED STATES, OR ANY ENTITY OR PERSON ACTING ON BEHALF OF U.S.
          CITIZENS OR RESIDENTS. THE ISSUING ENTITY RESERVES THE RIGHT TO REFUSE
          TOKEN SALES TO ANY INDIVIDUAL OR ENTITY WHO VIOLATES THIS PROHIBITION
          OR IS DEEMED TO BE NON-COMPLIANT WITH APPLICABLE LAWS.
        </p>
        <p>
          Jurisdiction and Governing Law: THIS DISCLAIMER AND ALL PARTICIPATION
          IN THE TOKEN SALE SHALL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE
          WITH THE LAWS OF THE APPLICABLE JURISDICTION. PARTICIPANTS EXPRESSLY
          WAIVE ANY RIGHT TO A TRIAL BY JURY AND AGREE THAT ALL DISPUTES SHALL
          BE RESOLVED THROUGH BINDING ARBITRATION OR IN A COURT OF COMPETENT
          JURISDICTION.
        </p>
        <p>
          Final Acknowledgement: BY PARTICIPATING IN THE TOKEN SALE OR USING
          THIS TOKEN, PURCHASERS ACKNOWLEDGE THAT THEY HAVE READ, UNDERSTOOD,
          AND AGREED TO THIS DISCLAIMER IN FULL. PARTICIPANTS FURTHER AGREE THAT
          THE ISSUING ENTITY SHALL NOT BE HELD LIABLE FOR ANY MISINTERPRETATION
          OR MISUSE OF THIS DOCUMENT.
        </p>
      </div>
    </div>
  );
};

export default Popup;

import img1 from "../images/svg/icon-1.svg";
import img2 from "../images/svg/icon-2.svg";
import img3 from "../images/svg/icon-3.svg";
import img4 from "../images/svg/icon-4.svg";

const dataPortfolio = [
  {
    id: 1,
    img: img1,
    title: "Connect your wallet",
    desc: "Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: 2,
    img: img2,
    title: "Buy your NFT",
    desc: "Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: 3,
    img: img3,
    title: "Create collection",
    desc: "Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: 4,
    img: img4,
    title: "Sell your NFT",
    desc: "Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
];

export default dataPortfolio;
